<template>
	<div class="fixed">
		<img :src="require('@/assets/core/logo.png')" alt="Chiesi México" class="w-48 sm:w-56">
	</div>

	<div class="max-w-3xl space-y-4 pt-24 sm:pt-28 px-6 sm:mx-10 md:mx-20 pb-10">
		<h1 class="font-semibold text-xl">Aviso de privacidad</h1>

		<p>
		CHIESI MEXICO, S.A. DE C.V. (“CHIESI”), con domicilio en Avenida Coyoacán 1622 Edificio 2 Piso 1- 208a Colonia del Valle, Delegación Benito Juárez, Ciudad de México, C.P 03100, le informa a continuación los lineamientos para la protección de la información que Usted proporciona a la Empresa, de conformidad con las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”) su Reglamento y los Lineamientos para el Aviso de Privacidad.</p>

		<p>
		Este documento revela la política de privacidad para el Tratamiento de Datos Personales recolectados por la Empresa (el “Aviso de Privacidad”). Cualquier información que sea recolectada por la Empresa de Usted será clasificada por la Empresa conforme a las siguientes categorías: “Datos Personales” – significa cualquier información escrita o electrónica concerniente a una persona física identificada o identificable. La información será considerada identificada o identificable si incluye alguno de los siguientes elementos:</p>

		<p>a) Datos de Identificación, como su nombre completo, firma autógrafa, R.F.C., C.U.R.P., edad, género, número de identificación, lugar y fecha de nacimiento, estado civil, imagen digital o fotográfica, huella dactilar.</p>

		<p>b) Datos particulares de contacto, como su domicilio, correo electrónico, teléfono fijo y teléfono celular.</p>

		<p>c) Datos financieros, como su número de cuenta bancaria.</p>

		<p>d) Video Grabaciones, Chiesi podrá video grabar testimonios, declaraciones u opiniones.</p>

		<p>Los Datos Personales no incluyen información que se encuentra públicamente disponible.</p>

		<p>“Datos Personales Sensibles” – significa cualquier Dato Personal referente a la salud, preferencia sexual, origen racial o étnico, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas o cualquier información identificada o identificable que afecte a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave a dicha persona.</p>
		<p>Chiesi tratará Testimonios, declaraciones, efectos terapéuticos de medicamentos y cualquier otra circunstancia que tenga que ver con su experiencia personal con su padecimiento, o bien el de un familiar cercano suyo como datos Sensibles.</p>

		<h2 class="font-semibold">Tratamiento de Datos Personales</h2>

		<p>Los datos personales que tratamos, los destinamos únicamente en función del cumplimiento de las siguientes:</p>

		<p>A. Finalidades Primarias o Necesarias: siendo aquellas necesarias en virtud de la invitación de Chiesi, es decir para: (a) compartir durante el evento la experiencia que ha tenido como Profesional de la Salud/paciente; (b) dar a conocer las vivencias y retos que como Profesional de la salud/paciente ha enfrentado; (c) enriquecer a los asistentes con las enseñanzas que como Profesional de la Salud/paciente ha tenido derivado de una condición médica específica.</p>
		<p>B. Finalidades Secundarias o No Necesarias: aquellas que no son necesarias o relacionadas con la relación contractual que tiene con Chiesi, y que usted solicita voluntariamente: (a) invitarlo a participar en eventos, ferias de salud, promociones, concursos y talleres informativos no laborales; (b) para fines estadísticos; (c) realizar encuestas.</p>

		<h2 class="font-semibold">¿Puedo negarme a las Finalidades Secundarias?</h2>
		<p>En caso de no autorizar a Chiesi para tratar sus datos personales en cumplimiento de las Finalidades Secundarias antes expuestas, podrá hacerlo enviando la siguiente declaración vía correo electrónico a e.mejia@chiesi.com conjuntamente con su nombre completo: “No autorizo que mis datos personales sean utilizados para llevar a cabo la(s) finalidad(es) secundaria(s) siguiente(s): (enlistar cada una)”. La revocación será efectiva en la fecha en que se haya acusado la recepción de la notificación</p>

		<h2 class="font-semibold">Derechos que le corresponden respecto a sus datos personales</h2>

		<p>Usted puede ejercer los derechos de Acceso, Rectificación, Cancelación y Oposición (derechos ARCO) respecto a sus datos personales. El derecho de acceso procede cuando usted requiere saber cuáles de sus datos personales están contenidos en las bases de datos de Chiesi, para qué se utilizan y, en general, las condiciones del tratamiento de sus datos personales; el derecho de rectificación procede cuando usted requiere que se corrijan o actualicen sus datos personales en caso de ser inexactos o incompletos; el derecho de cancelación es procedente cuando se requiere que los datos personales sean dados de baja total o parcialmente de las bases de datos de Chiesi, por haberse cumplido la finalidad para la cual fueron recabados; por último, usted podrá oponerse por causa legítima al tratamiento de sus datos personales.</p>

		<p>Usted puede solicitar que se limite el uso o divulgación de sus datos personales y revocar el consentimiento que haya otorgado para el tratamiento de los mismos, siempre y cuando las finalidades no sean necesarias de acuerdo a la relación contractual o por señalamiento expreso en la legislación aplicable.</p>

		<p>Como regla general, Chiesi no distribuye su información a terceros autorizados. Asimismo, se compartirá la información personal que sea estrictamente necesaria en cumplimiento a la legislación aplicable o conforme a los requerimientos provenientes de autoridades competentes.</p>

		<h2 class="font-semibold">Consentimiento Expreso</h2>

		<p>Manifiesto que he leído el presente aviso de privacidad, entiendo su contenido y me doy por informado respecto al tratamiento que Chiesi dará a mis datos personales de conformidad con lo que aquí se establece, durante el periodo de la relación con Chiesi y posteriormente, en términos de lo previsto en las leyes aplicables.</p>

		<p>Confirmo que he informado a mis familiares o beneficiarios de las cuales he proporcionado datos personales, sobre el tratamiento que se hará de sus datos personales.</p>		
	</div>
</template>
